import { Route, Routes, Navigate } from "react-router-dom"
import React from "react";
import Main from "./Main";
import Reviews from "./Reviews";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/reviews" element={<Reviews />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

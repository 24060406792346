import './App.css';
import React from 'react';

function Main() {

  return (
    // <button className='return-button' onClick={() => { returnToPricing() }}><svg className='return-button-svg' version="1.1" xmlns="http://www.w3.org/2000/svg" width="18.4961" height="30.8305">  <g>   <rect height="30.8305" opacity="0" width="18.4961" x="0" y="0" /><path d="M0 7.40234C0 7.64648 0.107422 7.87109 0.302734 8.05664L6.78711 30.5312C6.98242 30.7168 7.1875 30.8047 7.42188 30.8047C7.90039 30.8047 8.28125 30.4531 8.28125 13.9648C8.28125 13.7305 8.19336 13.4961 8.03711 13.3496L5.84961 11.123L1.98242 7.59766L1.77734 8.07617L4.92188 8.27308L17.2754 8.27308C17.7832 8.27308 18.1348 7.91016 18.1348 7.40234C18.1348 6.89453 17.7832 6.5332 17.2754 6.5332L4.92188 6.5332L1.77734 6.72852L1.98242 7.2168L5.84961 3.68164L8.03711 1.45508C8.19336 1.29883 8.28125 1.07422 8.28125 0.839844C8.28125 0.351562 7.90039 0 7.42188 0C7.1875 0 6.98242 0.078125 6.76758 0.292969L0.302734 6.74805C0.107422 6.93359 0 7.1582 0 7.40234Z" fill="black" /></g></svg>Return to pricing</button>
    <div className="App">
      <div className='main'>
        <div className='main-main'>
          <div className='main-main-main' style={{ alignItems:'center', width:400 }}>
            <img alt='adobe_king logo' src='/favicon.ico' />
            <h1>AdobeKing (Adobe_King)</h1>
            <p style={{ textAlign:'center', width:'100%' }}>The best Adobe Creative Cloud subscription provider, for individuals AND businesses.</p>
            <p style={{ textAlign:'center', width:'100%' }}>We have a new website:</p>
            <code style={{ fontSize:28, marginTop:20 }}><a href='https://cheapcc.net'>cheapcc.net</a></code>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Rating } from 'react-simple-star-rating'

function Reviews() {
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [rating, setRating] = useState(0)
  const [newReview, setNewReview] = useState({
    date: '',
    name: '',
    email: '',
    review: '',
  });

  function formatEmailSameLength(emilString) {
    var splitEmail = emilString.split("@")
    var domain = splitEmail[1];
    var name = splitEmail[0];
    return name.substring(0, 3).concat(Array(name.length - 3).join("*")).concat("@").concat(domain)
  }

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.post('/.netlify/functions/getReview');
        setReviews(response.data);
      } catch (error) {
        console.error('Error while fetching reviews:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReviews();
  }, []);

  const handleSubmitReview = async (event) => {
    event.preventDefault();

    try {

      const timestamp = Math.floor(Date.now() / 1000);
      const data = { ...newReview, date: timestamp.toString(), stars: rating };

      await axios.post('/.netlify/functions/addReview', { data });

      setNewReview({
        date: '',
        name: '',
        email: '',
        review: '',
      });
      setShowForm(false);

    } catch (error) {
      console.error('Error while adding a review:', error);
    }
  };

  const handleRating = (rate) => {
    setRating(rate)
  }

  return (
    <div className='reviews-container'>
      {isLoading && (
        <div className="loading-div">
          <div style={{ padding: 3 }} className="spinner" id="spinner">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
              width={201}
              height={201}
              style={{
                shapeRendering: 'auto',
                display: 'block',
                background: 'transparent',
                width: '100',
                height: '100',
                alignSelf: 'center',
                justifySelf: 'center',
                marginTop: 200,
              }}
            >
              <g>
                <circle
                  strokeDasharray="136.659280431156 47.553093477052"
                  r="29"
                  strokeWidth="5"
                  stroke="#000000"
                  fill="none"
                  cy="50"
                  cx="50"
                >
                  <animateTransform
                    keyTimes="0;1"
                    values="0 50 50;360 50 50"
                    dur="1.3513513513513513s"
                    repeatCount="indefinite"
                    type="rotate"
                    attributeName="transform"
                  ></animateTransform>
                </circle>
                <g></g>
              </g>
            </svg>
          </div>
        </div>
      )}

      {!isLoading && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className='reviews-list'>
            <h1 style={{ width: 'fit-content' }}>Reviews</h1>
            {reviews.map((review) => (
              <div>
                <h3 style={{ marginBottom: 10 }}>{review[1]}<span style={{ fontWeight: 'lighter', color: '#596B76', fontSize: 16, marginLeft: 10 }}>{formatEmailSameLength(review[2])}</span></h3>
                <Rating size={20} initialValue={parseFloat(review[4])} readonly={true} allowFraction={true} />
                <p className='review-p'>{review[3]}</p>
              </div>
            ))}
          </div>
          <div className='review-form-container'>
            <button onClick={() => setShowForm(!showForm)}>
              {showForm ? 'Close' : 'Submit a Review'}
            </button>
            {showForm && (
              <form className='review-form' onSubmit={handleSubmitReview}>
                <div className='input-inside'>
                  <label htmlFor="name">Your name:</label>
                  <input
                    type="name"
                    id="name"
                    value={newReview.name}
                    onChange={(e) =>
                      setNewReview({ ...newReview, name: e.target.value })
                    }
                    required
                  />
                </div>
                <div className='input-inside'>
                  <label htmlFor="email">Your e-mail:</label>
                  <input
                    type="email"
                    id="email"
                    value={newReview.email}
                    onChange={(e) =>
                      setNewReview({ ...newReview, email: e.target.value })
                    }
                    required
                  />
                </div>
                <div className='input-inside'>
                  <label htmlFor="stars">Your rating:</label>
                  <Rating size={25} onClick={handleRating} allowFraction={true} />
                </div>
                <div className='input-inside'>
                  <label htmlFor="review">Your review:</label>
                  <textarea
                    id="review"
                    maxlength="1000"
                    rows={7}
                    value={newReview.review}
                    onChange={(e) =>
                      setNewReview({ ...newReview, review: e.target.value })
                    }
                    required
                  />
                </div>
                <button type="submit">Submit</button>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Reviews;
